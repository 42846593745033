const NAVIGATION = [
  // {
  //   title: 'Tourenfotos',
  //   icon: 'mdi-camera',
  //   route: { name: 'PhotosEvents' },
  // },
  // {
  //   title: 'Sammlungen',
  //   icon: 'mdi-image-album',
  //   route: { name: 'PhotosCollections' },
  // },
  //   {
  //     title: 'Neue Nachricht',
  //     icon: 'mdi-email-plus',
  //     route: { name: 'MailCreate' },
  //   },
  //   {
  //     title: 'Mailing Listen',
  //     icon: 'mdi-contacts',
  //     route: { name: 'MailLists' },
  //   },
  //   {
  //     title: 'Statistik',
  //     icon: 'mdi-chart-timeline-variant',
  //     route: { name: 'MailStatistics' },
  //   },
]

export {
  NAVIGATION,
}
